import {
  axiosInit
} from '@/fmlib'
import * as url from './url'

import sysEnv from '@/env/env'

import store from '@/store'

import Vue from 'vue'

export const baseUrl = sysEnv.apiAdr

let showMessageFun = (msg) => {
  Vue.prototype.$notice.error({
    title: '系统提示',
    desc: msg
  })
}

let showMessageFunNoNotice = (msg) => {
  console.log('api调用异常:', msg)
}

export const getHeaderParm = () => {
  return {
    token: store.getters.token,
    roleIds: store.getters.currentRole ? store.getters.currentRole.id : ''
  }
}

let axiosInitOne = new axiosInit.axiosInit({baseUrl, showMessageFun, getHeaderParm})
let axiosInitOneNoNotice = new axiosInit.axiosInit({baseUrl, showMessageFun: showMessageFunNoNotice, getHeaderParm})

export const request = axiosInitOne.request
export const requestNoNotice = axiosInitOneNoNotice.request

let httpRequestMethods = axiosInitOne.httpRequestMethods

// file
export const fileRequest = {
  mkdir: function (parm) {
    return request(url.file.mkdir, httpRequestMethods.POST, parm)
  },
  getFileData: function (parm) {
    return request('/fms/file', httpRequestMethods.GET, parm)
  },
  upload: function (parm) {
    return request(url.file.upload, httpRequestMethods.POST, parm, {
      contentType: 'multipart/form-data'
    })
  },
  get: function (parm) {
    return requestNoNotice(url.file.download, httpRequestMethods.GET, parm)
  },
  download: function (parm) {
    return request(url.file.download, httpRequestMethods.GET, parm, {
      responseType: 'blob'
    })
  },
  del: function (parm) {
    return request(url.file.del, httpRequestMethods.DELETE, parm)
  },
  deal: function () {
    return request('/fms/file/deal', httpRequestMethods.GET, {})
  }
}

export const generateBasisRequest = function (urlParm) {
  return {
    get: function (parm) {
      return request(urlParm.basUrl, httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(urlParm.basUrl, httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(urlParm.parmUrl(id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(urlParm.parmUrl(id), httpRequestMethods.DELETE)
    }
  }
}

export const generateBasisDataRequest = function (name) {
  return {
    get: function (parm) {
      return request(url.data.get(name), httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(url.data.add(name), httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(url.data.update(name, id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(url.data.del(name, id), httpRequestMethods.DELETE)
    }
  }
}

export const generateCommonRequest = function (name) {
  return {
    get: function (parm) {
      return request(url.common.get(name), httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(url.common.add(name), httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(url.common.update(name, id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(url.common.del(name, id), httpRequestMethods.DELETE)
    }
  }
}

export const refQueryRequestHander = async function (key, parm) {
  return await request(url.refQuery.get(key), httpRequestMethods.GET, parm)
}

// systemApis
export function systemApisGet () {
  return request(url.systemApis.url, httpRequestMethods.GET)
}

// menuApis
export function menuApisUpate (id, parm) {
  return request(url.menuApis.parmUrl(id), httpRequestMethods.POST, parm)
}

export const userRequest = generateBasisRequest(url.user)

export const roleRequest = generateBasisRequest(url.role)

export const funRequest = Object.assign(generateBasisRequest(url.fun), {
  updateApi (id, parm) {
    return request(url.fun.updateApi(id), httpRequestMethods.POST, parm)
  }
})

export const menuRequest = generateBasisRequest(url.menu)

// auth
export function login (parm) {
  return request(url.auth.login, httpRequestMethods.POST, parm)
}

export function getLoginPubKey (parm) {
  return request(url.auth.getPubKey, httpRequestMethods.POST, parm)
}

export function updatePwd (parm) {
  return request(url.auth.updatePwd, httpRequestMethods.PUT, parm)
}

export function loginUpdatePwd (parm, token) {
  let get = () => {
    return {
      token
    }
  }
  return new axiosInit.axiosInit({baseUrl, showMessageFun, getHeaderParm: get}).request(url.auth.updatePwd, httpRequestMethods.PUT, parm)
}

export function userLogOut () {
  return request(url.auth.logOut, httpRequestMethods.DELETE)
}

export function getUserInfo () {
  return request(url.auth.getInfo, httpRequestMethods.GET)
}

// resetPassword
export function resetPassword (id) {
  return request(url.resetPassword.parmUrl(id), httpRequestMethods.PUT)
}

export const ucenterRequest = {
  updateAuthorities (id, parm) {
    return request(url.ucenter.authorities(id), httpRequestMethods.POST, parm)
  },
  getAuthorities (parm) {
    return request(url.ucenter.users, httpRequestMethods.GET, parm)
  }
}

export const dataAuditLogReuqest = {
  get: function (parm) {
    return request(url.dataAuditLog.base, httpRequestMethods.GET, parm)
  }
}

export const workOperationReuqest = {
  getLog: function (parm) {
    return request(url.workOperation.log, httpRequestMethods.GET, parm)
  }
}

// confQuery
export const confQueryRequest = generateCommonRequest('conf_query')

export const workerRequest = Object.assign(generateCommonRequest('worker'), {
  getAll (parm) {
    return request('/basis/worker/all', httpRequestMethods.GET, parm)
  },
  getDetail (parm) {
    return request(url.worker.getDetail, httpRequestMethods.GET, parm)
  },
  getEdit (parm) {
    return request('/basis/worker/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/worker/edit', httpRequestMethods.PUT, parm)
  },
  batchSave (parm) {
    return request('/basis/worker/batch', httpRequestMethods.POST, parm)
  }
})

export const workerTypeRequest = generateCommonRequest('worker_type')

export const orgTypeRequest = generateCommonRequest('org_type')

export const positionRequest = Object.assign(generateCommonRequest('position'), {
  getBaseCount (parm) {
    return request(url.position.getBaseCount, httpRequestMethods.GET, parm)
  }
})

export const orgRequest = Object.assign(generateCommonRequest('org'), {
  getAll (parm) {
    return request(url.org.getAll, httpRequestMethods.GET, parm)
  },
  getTree (parm) {
    return request(url.org.getTree, httpRequestMethods.GET, parm)
  },
  getAllTree (parm) {
    return request(url.org.getAllTree, httpRequestMethods.GET, parm)
  }
})

export const orgRateGroupRequest = Object.assign(generateBasisDataRequest('OrgRateGroup'), {
  get (parm) {
    return refQueryRequestHander('get_org_rate_group', parm)
  },
  getAll (parm) {
    return generateBasisDataRequest('OrgRateGroup').get(parm)
  }
})

export const orgRateDetailRequest = Object.assign(generateBasisDataRequest('OrgRateDetail'), {
  get (parm) {
    return refQueryRequestHander('get_org_rate_detail', parm)
  },
  getAll (parm) {
    return generateBasisDataRequest('OrgRateDetail').get(parm)
  }
})

export const orderTypeRequest = Object.assign(generateBasisDataRequest('OrderType'), {
  get (parm) {
    return generateBasisDataRequest('OrderType').get(parm)
  },
  getAll (parm) {
    return generateBasisDataRequest('OrderType').get(parm)
  }
})

export const orderRequest = Object.assign(generateBasisDataRequest('HisOrder'), {
  get (parm) {
    return request(url.hisOrder.get, httpRequestMethods.GET, parm)
  }
})

export function salaryCalculate (parm) {
  return request(url.salary.calculate, httpRequestMethods.POST, parm)
}

export const salaryRequest = {
  get_worker_salary (parm) {
    return request(url.salary.get_worker_salary, httpRequestMethods.GET, parm)
  },
  volume (parm) {
    return request(url.salary.volume, httpRequestMethods.GET, parm)
  },
  init (parm) {
    return request(url.salary.init, httpRequestMethods.POST, parm)
  },
  initWorker (parm) {
    return request(url.salary.initWorker, httpRequestMethods.POST, parm)
  },
  addOrg (parm) {
    return request(url.salary.addOrg, httpRequestMethods.POST, parm)
  },
  addWorker (parm) {
    return request(url.salary.addWorker, httpRequestMethods.POST, parm)
  },
  count (parm) {
    return request(url.salary.count, httpRequestMethods.GET, parm)
  }
}

export const orgSalaryRequest = Object.assign(generateBasisDataRequest('OrgSalary'), {
  get (parm) {
    return refQueryRequestHander('get_org_salary', parm)
  },
  calculate (parm) {
    return salaryCalculate(Object.assign({type: 'org'}, parm))
  }
})

export const workerSalaryRequest = Object.assign(generateBasisDataRequest('WorkerSalary'), {
  get (parm) {
    return refQueryRequestHander('get_worker_salary', parm)
  },
  calculate (parm) {
    return salaryCalculate(Object.assign({type: 'worker'}, parm))
  }
})

export const agreementRequest = Object.assign(generateCommonRequest('agreement'), {
  getWarning (parm) {
    return request(url.agreement.warning, httpRequestMethods.GET, parm)
  },
  sendWarning (parm) {
    return request('/basis/agreement/send_warning', httpRequestMethods.POST, parm)
  },
  getTurn (parm) {
    parm.jobTypeC = '试用期'
    parm.needTry = '是'
    return generateCommonRequest('agreement').get(parm)
  }
})

export const avgSalaryRequest = Object.assign(generateBasisDataRequest('AvgSalary'), {
  updateDatas (parm) {
    return request(url.salary.updateAvg, httpRequestMethods.POST, parm)
  }
})

export const sourceHisOrderRequest = generateBasisDataRequest('SourceHisOrder')

export const dataSyncLogRequest = {
  get () {
    return request(url.dataSyncLog.getLastLog, httpRequestMethods.GET)
  },
  syncData () {
    return request(url.dataSyncLog.syncData, httpRequestMethods.POST)
  }
}

// appraisal
export const appraisalRequest = generateBasisDataRequest('Appraisal')

// appraisal_group
export const appraisalGroupRequest = generateBasisDataRequest('AppraisalGroup')

// appraisal_question
export const appraisalQuestionRequest = generateBasisDataRequest('AppraisalQuestion')

// appraisal_group_detail
export const appraisalGroupDetailRequest = Object.assign(generateBasisDataRequest('AppraisalGroupDetail'), {
  get (parm) {
    return refQueryRequestHander('get_appraisal_group_detail', parm)
  },
  getMy (parm) {
    return refQueryRequestHander('get_my_appraisal_group_detail', parm)
  }
})

// appraisal_result
export const appraisalResultRequest = generateBasisDataRequest('AppraisalResult')

// appraisal_result_detail
export const appraisalResultDetailRequest = Object.assign(generateBasisDataRequest('AppraisalResultDetail'), {
  get (parm) {
    return refQueryRequestHander('get_appraisal_result_detail', parm)
  }
})

// jobTitle
export const jobTitleRequest = Object.assign(generateCommonRequest('job_title'), {
  getQueue (parm) {
    return request(url.jobTitle.getQueue, httpRequestMethods.GET, parm)
  },
  getMatch (parm) {
    return request(url.jobTitle.getMatch, httpRequestMethods.GET, parm)
  },
  getQueueWarning (parm) {
    return request(url.jobTitle.getQueueWarning, httpRequestMethods.GET, parm)
  },
  getCurrent (parm) {
    return request(url.jobTitle.getCurrent, httpRequestMethods.GET, parm)
  }
})

// certificate
export const certificateRequest = generateCommonRequest('certificate')

// workerJobTitle
export const workerJobTitleRequest = Object.assign(generateCommonRequest('worker_job_title'), {
  getEdit (parm) {
    return request('/basis/worker_job_title/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/worker_job_title/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/worker_job_title/edit', httpRequestMethods.POST, parm)
  }
})

// WorkerCertificate
export const workerCertificateRequest = Object.assign(generateCommonRequest('worker_certificate'), {
  getEdit (parm) {
    return request('/basis/worker_certificate/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/worker_certificate/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/worker_certificate/edit', httpRequestMethods.POST, parm)
  }
})

// wage
export const wageRequest = Object.assign(generateCommonRequest('wage'), {
  clear (parm) {
    return request(url.wage.clear, httpRequestMethods.POST, parm)
  }
})

export function dataerImportRequest (file, sparm) {
  console.log(sparm, 'sparmsparm')
  let parm = new FormData()
  parm.append('file', file)
  Object.keys(sparm).forEach(v => {
    parm.append(v, sparm[v])
  })
  return request(url.dataerImport.url, httpRequestMethods.POST, parm, {
    contentType: 'multipart/form-data'
  })
}

// train
export const trainRequest = Object.assign(generateCommonRequest('train'), {
  getEdit (parm) {
    return request('/basis/train/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/train/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/train/edit', httpRequestMethods.POST, parm)
  }
})

// rew_pun
export const rewPunRequest = Object.assign(generateCommonRequest('rew_pun'), {
  getEdit (parm) {
    return request('/basis/rew_pun/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/rew_pun/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/rew_pun/edit', httpRequestMethods.POST, parm)
  }
})

// rew_pun
export const operationLogRequest = {
  get (parm) {
    return request(url.operationLog.get, httpRequestMethods.GET, parm)
  }
}

// ask_leave
export const askLeaveRequest = Object.assign(generateCommonRequest('ask_leave'), {
  getMy (parm) {
    return request('/basis/ask_leave/my', httpRequestMethods.GET, parm)
  },
  off (id, parm) {
    return request('/basis/ask_leave/off/' + id, httpRequestMethods.POST, parm)
  },
  backOff (id) {
    return request('/basis/ask_leave/back_off/' + id, httpRequestMethods.POST)
  }
})

// askLeaveApply
export const askLeaveApplyRequest = Object.assign(generateCommonRequest('ask_leave_apply'), {
  switchStatus (id, parm) {
    return request('/basis/ask_leave_apply/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/basis/ask_leave_apply/by_auth', httpRequestMethods.GET, parm)
  },
  assign (id, parm) {
    return request('/basis/ask_leave_apply/assign/' + id, httpRequestMethods.PUT, parm)
  },
  files (id, parm) {
    return request('/basis/ask_leave_apply/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getType (parm) {
    return request('/basis/ask_leave_apply/type', httpRequestMethods.GET, parm)
  },
  addType (parm) {
    return request('/basis/ask_leave_apply/type', httpRequestMethods.POST, parm)
  },
  updateType (id, parm) {
    return request('/basis/ask_leave_apply/type/' + id, httpRequestMethods.PUT, parm)
  },
  updateTypeFlow (id, parm) {
    return request('/basis/ask_leave_apply/type/flow/' + id, httpRequestMethods.PUT, parm)
  },
  delType (id) {
    return request('/basis/ask_leave_apply/type/' + id, httpRequestMethods.DELETE)
  }
})

// his_work
export const hisWorkRequest = Object.assign(generateCommonRequest('his_work'), {
  getEdit (parm) {
    return request('/basis/his_work/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/his_work/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/his_work/edit', httpRequestMethods.POST, parm)
  }
})

// placard
export const placardRequest = generateCommonRequest('placard')

// worker_edu
export const workerEduRequest = Object.assign(generateCommonRequest('worker_edu'), {
  getEdit (parm) {
    return request('/basis/worker_edu/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/worker_edu/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/worker_edu/edit', httpRequestMethods.POST, parm)
  }
})

// workerAuth
export const workerAuthRequest = generateCommonRequest('worker_auth')

export const configsRequest = generateCommonRequest('sys_config')

export const dataAppleRequest = {
  getDraft (parm) {
    return request('/basis/modify_apply/draft', httpRequestMethods.GET, parm)
  },
  getWait (parm) {
    return request('/basis/modify_apply/wait', httpRequestMethods.GET, parm)
  },
  getWaitDetail (parm) {
    return request('/basis/modify_apply/wait/detail', httpRequestMethods.GET, parm)
  },
  saveDraft (parm) {
    return request('/basis/modify_apply/draft', httpRequestMethods.POST, parm)
  },
  multipleSubmit (workerId, parm) {
    return request('/basis/modify_apply/multiple/submit/' + workerId, httpRequestMethods.POST, parm)
  },
  check (id, parm) {
    return request('/basis/modify_apply/check/' + id, httpRequestMethods.POST, parm)
  },
  submitByWorkerId (id, parm) {
    return request('/basis/modify_apply/submit/worker/' + id, httpRequestMethods.POST, parm)
  }
}

// count_group
export const countGroupRequest = Object.assign(generateCommonRequest('count_group'), {
  delDetail (id) {
    return request('/basis/count_group/detail/' + id, httpRequestMethods.DELETE)
  },
  generateDetail (id) {
    return request('/basis/count_group/detail/' + id, httpRequestMethods.POST)
  }
})

// count_worker
export const countWorkerRequest = generateCommonRequest('count_worker')

// official_document
export const officialDocumentRequest = Object.assign(generateCommonRequest('official_document'), {
  back (id) {
    return request('/basis/official_document/back/' + id, httpRequestMethods.PUT)
  },
  getDetail (parm) {
    return request('/basis/official_document/detail', httpRequestMethods.GET, parm)
  }
})

// official_document_handle_step
export const officialDocumentHandleStepRequest = generateCommonRequest('official_document_handle_step')

// official_document_handle
export const officialDocumentHandleRequest = Object.assign(generateCommonRequest('official_document_handle'), {
  getMy (parm) {
    return request('/basis/official_document_handle/my', httpRequestMethods.GET, parm)
  }
})

// activity
export const activityRequest = generateCommonRequest('activity')


// performance

export const workerWageRequest = {
  get: function (parm) {
    return request('/performance/worker_wage', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/performance/worker_wage', httpRequestMethods.POST, parm)
  },
  update: function (id, parm) {
    return request('/performance/worker_wage', httpRequestMethods.POST, parm)
  },
  updateValue: function (id, parm) {
    return request('/performance/worker_wage/' + id, httpRequestMethods.PUT, parm)
  },
  batch: function (parm) {
    return request('/performance/worker_wage/batch', httpRequestMethods.POST, parm)
  },
  batchSave: function (parm) {
    return request('/performance/worker_wage/batch', httpRequestMethods.PUT, parm)
  },
  del: function (id) {
    return request('/performance/worker_wage/' + id, httpRequestMethods.DELETE)
  }
}

export const wageJobTitleRequest = {
  get: function (parm) {
    return request('/performance/wage_job_title', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/performance/wage_job_title', httpRequestMethods.POST, parm)
  },
  update: function (id, parm) {
    return request('/performance/wage_job_title/' + id, httpRequestMethods.PUT, parm)
  },
  del: function (id) {
    return request('/performance/wage_job_title/' + id, httpRequestMethods.DELETE)
  }
}

export const wageLevelRequest = {
  get: function (parm) {
    return request('/performance/wage_level', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/performance/wage_level', httpRequestMethods.POST, parm)
  },
  update: function (id, parm) {
    return request('/performance/wage_level/' + id, httpRequestMethods.PUT, parm)
  },
  del: function (id) {
    return request('/performance/wage_level/' + id, httpRequestMethods.DELETE)
  }
}

export const performanceWorkerRequest = {
  get: function (parm) {
    return request('/performance/worker', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/performance/worker', httpRequestMethods.POST, parm)
  },
  update: function (id, parm) {
    return request('/performance/worker/' + id, httpRequestMethods.PUT, parm)
  },
  del: function (id) {
    return request('/performance/worker/' + id, httpRequestMethods.DELETE)
  }
}

export const nucleicReportRequest = {
  get: function (parm) {
    return request('/basis/nucleic_report', httpRequestMethods.GET, parm)
  },
  update: function (parm, type = '') {
    return request('/basis/nucleic_report' + (type ? ('/' + type) : ''), httpRequestMethods.POST, parm)
  },
  save: function (id, parm, type = '') {
    return request('/basis/nucleic_report' + (type ? ('/' + type) : '') + '/' + id, httpRequestMethods.PUT, parm)
  },
  del: function (parm) {
    return request('/basis/nucleic_report', httpRequestMethods.DELETE, parm)
  },
  cydd: function () {
    return request('/basis/nucleic_report/cydd', httpRequestMethods.GET)
  },
  counts: function (parm) {
    return request('/basis/nucleic_report/count', httpRequestMethods.GET, parm)
  },
  jsrq: function (parm) {
    return request('/basis/nucleic_report/jsrq', httpRequestMethods.POST, parm)
  }
}

export const analysisRequest = {
  certificateLevel (parm) {
    return request('/basis/worker/certificate_level', httpRequestMethods.GET, parm)
  }
}

export const statusManageRequest = {
  switchConfig (parm) {
    return request('/basis/status_manage/switch_config', httpRequestMethods.GET, parm)
  },
  config (parm) {
    return request('/basis/status_manage/config', httpRequestMethods.GET, parm)
  },
  reloadGroup () {
    return request('/basis/status_manage/reload', httpRequestMethods.GET)
  },
  getGroup () {
    return request('/basis/status_manage', httpRequestMethods.GET)
  },
  updateGroup (parm) {
    return request('/basis/status_manage', httpRequestMethods.POST, parm)
  },
  delGroup (id) {
    return request('/basis/status_manage/' + id, httpRequestMethods.DELETE)
  },
  updateStatus (parm) {
    return request('/basis/status_manage/status', httpRequestMethods.POST, parm)
  },
  delStatus (id) {
    return request('/basis/status_manage/status/' + id, httpRequestMethods.DELETE)
  },
  addSwitch (parm) {
    return request('/basis/status_manage/switch', httpRequestMethods.POST, parm)
  },
  updateSwitch (id, parm) {
    return request('/basis/status_manage/switch/' + id, httpRequestMethods.PUT, parm)
  },
  delSwitch (id) {
    return request('/basis/status_manage/switch/' + id, httpRequestMethods.DELETE)
  },
  addAction (parm) {
    return request('/basis/status_manage/action', httpRequestMethods.POST, parm)
  },
  updateAction (id, parm) {
    return request('/basis/status_manage/action/' + id, httpRequestMethods.PUT, parm)
  },
  delAction (id) {
    return request('/basis/status_manage/action/' + id, httpRequestMethods.DELETE)
  },
  addCondition (parm) {
    return request('/basis/status_manage/condition', httpRequestMethods.POST, parm)
  },
  updateCondition (id, parm) {
    return request('/basis/status_manage/condition/' + id, httpRequestMethods.PUT, parm)
  },
  delCondition (id) {
    return request('/basis/status_manage/condition/' + id, httpRequestMethods.DELETE)
  }
}

export const appraisalYearRequest = {
  get (parm) {
    return request('/basis/appraisal_year', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request('/basis/appraisal_year', httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request('/basis/appraisal_year/' + id, httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request('/basis/appraisal_year/' + id, httpRequestMethods.DELETE)
  },
  status (id, parm) {
    return request('/basis/appraisal_year/status/' + id, httpRequestMethods.PUT, parm)
  }
}

export const appraisalYearDetailsRequest = {
  get (parm) {
    return request('/basis/appraisal_year/detail', httpRequestMethods.GET, parm)
  },
  getMy (parm) {
    return request('/basis/appraisal_year/detail/my', httpRequestMethods.GET, parm)
  },
  update (id, parm) {
    return request('/basis/appraisal_year/detail/' + id, httpRequestMethods.PUT, parm)
  },
  status (id, parm) {
    return request('/basis/appraisal_year/detail/status/' + id, httpRequestMethods.PUT, parm)
  },
  check (parm) {
    return request('/basis/appraisal_year/check', httpRequestMethods.POST, parm)
  }
}