<style lang="less" scoped>
.file-manage {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  &.dragenter::after {
    content: attr(status);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    position: absolute;
    border: 1px dotted #EEE;
    background-color: rgba(47, 91, 234, .2);
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.item {
  width: 100px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all .3s;
  align-items: center;
  cursor: pointer;
  .cover {
    user-select: none;
    width: 32px;
    height: 32px;
    .icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    user-select: none;
    margin-top: 5px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .action {
    opacity: 0;
    transition: all .3s;
    display: flex;
    justify-content: space-between;
    .del, .save {
      cursor: pointer;
      color: #657180;
      border-radius: 5px;
      padding: 2px;
      font-size: 12px;
      box-sizing: border-box;
      transition: all .3s;
      margin: 0 4px;
    }
    .save {
      &:hover {
        color: #FFF;
        background-color: rgba(0, 153, 255, 0.788);
      }
    }
    .del {
      &:hover {
        color: #FFF;
        background-color: rgb(255, 0, 0, .4);
      }
    }
  }
  &:hover {
    background-color: #EEE;
    .action {
      opacity: 1;
    }
  }
}
</style>

<template>
  <div
    class="file-manage"
    :class="{dragenter: dragenter}"
    @drop="fileDrop"
    @dragenter="onDragenter"
    @dragover="onDragover"
    @dragend="onDragend">
    <div class="item" v-for="item in fileDatas" :key="item.file.id" @click="itemClick(item)">
      <div class="cover">
        <img v-if="item.loading" class="icon" src="/static/images/icon/file/loading.gif" />
        <img v-else-if="item.cover" class="icon" :src="Array.isArray(item.cover) && item.cover.length ? item.cover[0] : item.cover" />
        <img v-else class="icon" :src="'/static/images/icon/file/' + item.icon" />
      </div>
      <div class="name" :title="item.file.name">{{item.file.name}}</div>
      <div class="action">
        <span @click.stop="save(item)" class="save">下载</span>
        <span v-if="funs.del" @click.stop="del(item)" class="del">删除</span>
      </div>
    </div>
    <img v-if="!fileDatas.length && !funs.upload" src="/static/images/no-data.png" style="width: 100px;margin: 0 auto;" />
    <div @click="!loading.upload && $refs.upload.click()" v-if="funs.upload" class="item">
      <div class="cover">
        <img v-if="loading.upload" class="icon" src="/static/images/icon/file/loading.gif" />
        <i v-else class="iconfont icon-add icon" style="display: flex;align-items: center;justify-content: center;"></i>
      </div>
      <div class="name">上传文件</div>
    </div>
    <input type="file" style="display: none;" @change="upload()" ref="upload">
    <ImgPreview v-if="status.preview" @close="status.preview = false" :show="status.preview" :index="imgs.index" :imgs="imgs.covers" is-source/>
    <DocPreview v-if="status.viewer" :src="viewer.src" :data="viewer.data" :type="viewer.type" @close="status.viewer = false" />
  </div>
</template>

<script>
import {
  fileRequest
} from '@/api'

import ImgPreview from '@/components/base/file/preview/img'
import { isImg, isPdf, getExtension } from '@/components/base/file/lib.js'
import { loadResourcesByCache } from '@/components/base/file/libv2.js'
import DocPreview from '@/components/base/file/preview/doc'

import XLSX from 'xlsx'

function getIcon (name) {
  let icon = ['md', 'doc', 'gz', 'sh', 'jar', 'tar', 'exe', 'xls', 'txt', 'zip', 'ppt', 'pdf'].find(v => name.indexOf('.' + v) > -1)
  return icon ? (icon + '.png') : 'file.png'
}

export default {
  components: { ImgPreview, DocPreview },
  props: {
    fileDataIds: {
      type: Array
    },
    preview: {
      type: Boolean,
      default: true
    },
    funs: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      dragenter: false,
      fileDatas: [],
      loading: {
        upload: false
      },
      imgs: {
        index: 0,
        covers: []
      },
      status: {
        preview: false,
        viewer: false
      },
      viewer: {
        src: null,
        type: null,
        data: null
      }
    }
  },
  watch: {
    fileDataIds: {
      deep: true,
      handler () {
        this.loadData()
      }
    }
  },
  methods: {
    async fileDrop (event) {
      this.$el.setAttribute('status', '上传中...')
      event.preventDefault()
      let files = event.dataTransfer.files;
      if (files.length < 1)
          return;
      await this.upload(files)
      this.$el.setAttribute('status', '上传成功')
      this.dragenter = false
    },
    onDragenter (e) {
      this.$el.setAttribute('status', '释放文件即可上传')
      e.preventDefault()
      this.dragenter = true
    },
    onDragover (e) {
      e.preventDefault()
    },
    onDragend (e) {
      this.$el.setAttribute('status', '释放文件即可上传')
      e.preventDefault()
      this.dragenter = false
    },
    async itemClick (item) {
      if (!this.preview) {
        return
      }
      const ext = getExtension(item.file.name)
      if (isImg(item.file.name)) {
        const items = this.fileDatas.filter(v => Array.isArray(v.cover) ? v.cover.length : v.cover)
        const index = items.findIndex(v => v.file.id === item.file.id)
        this.imgs.covers = items.map(v => v.cover).flat()
        this.imgs.index = index
        this.status.preview = true
        const promise = items.map(v => loadResourcesByCache({ file: v.file, type: 'blob' }))
        const covers = await Promise.all(promise)
        this.imgs.covers = covers.flat()
      } else if (isPdf(item.file.name)) {
        const response = await fileRequest.download({ id: item.file.id })
        window.open(window.URL.createObjectURL(new Blob([response], { type: 'application/pdf ' })))
      } else if (['ppt', 'pptx'].includes(ext)) {
        this.$notice.warning('暂不支持ppt类文件预览，请下载查看')
      } else if (['doc', 'docx'].includes(ext)) {
        const response = await fileRequest.download({ id: item.file.id, size: 'small' })
        window.open(window.URL.createObjectURL(new Blob([response], { type: 'application/pdf ' })))
      } else if (['xls', 'xlsx'].includes(ext)) {
        const response = await fileRequest.download({ id: item.file.id })
        const buffer = await response.arrayBuffer()
        this.viewer.data = XLSX.read(buffer, {type: 'array'})
        this.viewer.type = 'excel'
        this.status.viewer = true
      }
    },
    loadCovers () {
      this.fileDatas.forEach(async (v, i) => {
        if (v.cover === null && isImg(v.file.name)) {
          this.$set(this.fileDatas[i], 'loading', true)
          let src = await loadResourcesByCache({ file: v.file, size: 'small', type: 'blob' })
          this.$set(this.fileDatas[i], 'loading', false)
          this.$set(this.fileDatas[i], 'cover', src)
        }
      })
    },
    async loadData () {
      if (this.funs.get) {
        if (this.fileDataIds && this.fileDataIds.length > 0) {
          const fileDatas = await fileRequest.getFileData({
            ids: this.fileDataIds.join(',')
          })
          this.fileDatas = fileDatas.map(file => {
            return {
              file: file,
              loading: false,
              cover: null,
              preview: null,
              icon: getIcon(file.name)
            }
          })
          this.loadCovers()
        } else {
          this.fileDatas = []
        }
      } else {
        this.fileDatas = []
      }
    },
    async upload (files) {
      files = files || this.$refs.upload.files
      if (files.length) {
        let file = files[0]
        let parm = new FormData()
        parm.append('file', file)
        this.$emit('uploadIng', true)
        this.loading.upload = true
        let data = await fileRequest.upload(parm)
        this.loading.upload = false
        this.fileDatas.push({
          file: data,
          loading: false,
          cover: null,
          preview: null,
          icon: getIcon(data.name)
        })
        this.loadCovers()
        this.$emit('uploadIng', false)
        this.$emit('addFile', data)
      }
    },
    async save (item) {
      const response = await fileRequest.download({ id: item.file.id })
      const blob = window.URL.createObjectURL(response)
      let a = document.createElement('a')
      a.href = blob
      a.download = item.file.name || new Date().getTime()
      a.click()
    },
    async del (item) {
      const confirm = await this.$dialog.confirm({ title: '确定删除么？' })
      if (confirm) {
        // await fileRequest.del({id: item.file.id})
        const file = this.fileDatas.find(({ file }) => file.id !== item.file.id)
        if (file && file.cover) {
          if (Array.isArray(file.cover)) {
            file.cover.forEach(cover => cover.indexOf('blob:') > -1 && window.URL.revokeObjectURL(cover))
          } else {
            file.cover.indexOf('blob:') > -1 && window.URL.revokeObjectURL(file.cover)
          }
        }
        this.fileDatas = this.fileDatas.filter(({ file }) => file.id !== item.file.id)
        this.$emit('delFile', item.file)
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
