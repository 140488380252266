import routerDataPerformance from './router_data_performance'

export default [
  {
    path: '/',
    name: 'app',
    component: () => import('@/views/app'),
    children: [
      routerDataPerformance,
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/app/nullPage')
      },
      {
        path: '/person',
        name: 'person.index',
        component: () => import('@/views/person/index')
      },
      {
        path: '/worker',
        name: 'worker',
        component: () => import('@/views/worker/index'),
        children: [{
          path: 'inforate',
          name: 'worker.inforate',
          component: () => import('@/views/worker/infoRate')
        },
        {
          path: 'worker',
          name: 'worker.worker',
          component: () => import('@/views/worker/worker')
        },
        {
          path: 'edit',
          name: 'worker.edit',
          component: () => import('@/views/person/edit_info/page')
        },
        {
          path: 'worker_in',
          name: 'worker.worker_in',
          component: () => import('@/views/worker/workerIn')
        },
        {
          path: 'worker_idcard',
          name: 'worker.worker_idcard',
          component: () => import('@/views/worker/workerIdCard')
        },
        {
          path: 'worker_edit',
          name: 'worker.worker_edit',
          component: () => import('@/views/worker/workerEdit')
        },
        {
          path: 'worker_out',
          name: 'worker.worker_out',
          component: () => import('@/views/worker/workerOut')
        },
        {
          path: 'his_work',
          name: 'worker.his_work',
          component: () => import('@/views/worker/hisWork')
        },
        {
          path: 'agreement',
          name: 'worker.agreement',
          component: () => import('@/views/worker/agreement')
        },
        {
          path: 'worker_edu',
          name: 'worker.worker_edu',
          component: () => import('@/views/worker/workerEdu')
        },
        {
          path: 'worker_auth',
          name: 'worker.worker_auth',
          component: () => import('@/susong/workerAuth')
        },
        {
          path: 'wage',
          name: 'worker.wage',
          component: () => import('@/views/worker/wage')
        },
        {
          path: 'wage_tongcheng',
          name: 'worker.wage_tongcheng',
          component: () => import('@/views/worker/wageTongcheng')
        },
        {
          path: 'train',
          name: 'worker.train',
          component: () => import('@/views/worker/train')
        },
        {
          path: 'rew',
          name: 'worker.rew',
          component: () => import('@/views/worker/rew')
        },
        {
          path: 'pun',
          name: 'worker.pun',
          component: () => import('@/views/worker/pun')
        },
        {
          path: 'agreement_warning',
          name: 'worker.agreement_warning',
          component: () => import('@/views/worker/agreementWarning')
        },
        {
          path: 'agreement_turn_warning',
          name: 'worker.agreement_turn_warning',
          component: () => import('@/views/worker/agreementTurnWarning')
        },
        {
          path: 'count',
          name: 'worker.count',
          component: () => import('@/views/worker/count')
        },
        {
          path: 'worker_job_title',
          name: 'worker.worker_job_title',
          component: () => import('@/views/worker/workerJobTitle'),
        },
        {
          path: 'job_title_current',
          name: 'worker.job_title_current',
          component: () => import('@/views/worker/jobTitleCurrent'),
        },
        {
          path: 'worker_job_current',
          name: 'worker.worker_job_current',
          component: () => import('@/views/worker/workerJobTitleCurrent'),
        },
        {
          path: 'worker_certificate',
          name: 'worker.worker_certificate',
          component: () => import('@/views/worker/workerCertificate'),
        },
        {
          path: 'worker_certificate_other',
          name: 'worker.worker_certificate_other',
          component: () => import('@/views/worker/workerCertificateOther'),
        },
        {
          path: 'worker_certificate_doctor_pra',
          name: 'worker.worker_certificate_doctor_pra',
          component: () => import('@/views/worker/workerCertificateDoctorPra')
        },
        {
          path: 'worker_certificate_doctor_pra_count',
          name: 'worker.worker_certificate_doctor_pra_count',
          component: () => import('@/views/worker/workerCertificateDoctorPraCount')
        },
        {
          path: 'worker_certificate_doctor_qua',
          name: 'worker.worker_certificate_doctor_qua',
          component: () => import('@/views/worker/workerCertificateDoctorQua')
        },
        {
          path: 'worker_certificate_pro_hire',
          name: 'worker.worker_certificate_pro_hire',
          component: () => import('@/views/worker/workerCertificateProHire'),
        },
        {
          path: 'job_title_promotion',
          name: 'worker.job_title_promotion',
          component: () => import('@/views/worker/workerJobTitlePromotion'),
        },
        {
          path: 'job_title_queue',
          name: 'worker.job_title_queue',
          component: () => import('@/views/worker/workerJobTitleQueue'),
        },
        {
          path: 'job_title_queue_warning',
          name: 'worker.job_title_queue_warning',
          component: () => import('@/views/worker/workerJobTitleQueueWarning'),
        }]
      },
      {
        path: '/conf',
        name: 'conf',
        component: () => import('@/views/conf'),
        children: [
          {
            path: 'conf_query',
            name: 'conf.conf_query',
            component: () => import('@/views/conf/conf_query'),
          }
        ]
      },
      {
        path: '/activity',
        name: 'activity',
        component: () => import('@/views/activity'),
        children: [
          {
            path: 'bus',
            name: 'activity.bus',
            component: () => import('@/views/activity/bus'),
          },
          {
            path: 'edu',
            name: 'activity.edu',
            component: () => import('@/views/activity/edu'),
          },
          {
            path: 'train',
            name: 'activity.train',
            component: () => import('@/views/activity/train'),
          }
        ]
      },
      {
        path: '/ask_leave',
        name: 'ask_leave',
        component: () => import('@/views/askleave'),
        children: [
          {
            path: 'apply',
            name: 'ask_leave.apply',
            component: () => import('@/views/askleave/apply'),
          },
          {
            path: 'apply_assign',
            name: 'ask_leave.apply_assign',
            component: () => import('@/views/askleave/applyAssign'),
          },
          {
            path: 'apply_record',
            name: 'ask_leave.apply_record',
            component: () => import('@/views/askleave/applyRecord'),
          },
          {
            path: 'record',
            name: 'ask_leave.record',
            component: () => import('@/views/askleave/record')
          },
          {
            path: 'my',
            name: 'ask_leave.my',
            component: () => import('@/views/askleave/my')
          },
          {
            path: 'home',
            name: 'ask_leave.home',
            component: () => import('@/views/askleave/home')
          },
          {
            path: 'switch_config',
            name: 'ask_leave.switch_config',
            component: () => import('@/views/askleave/switchConfig')
          }
        ]
      },
      {
        path: '/base',
        name: 'base',
        component: () => import('@/views/base'),
        children: [
          {
            path: 'org',
            name: 'base.org',
            component: () => import('@/views/base/org')
          },
          {
            path: 'placard',
            name: 'base.placard',
            component: () => import('@/views/base/placard')
          },
          {
            path: 'org_type',
            name: 'base.org_type',
            component: () => import('@/views/base/orgType'),
          },
          {
            path: 'position',
            name: 'base.position',
            component: () => import('@/views/base/position')
          },
          {
            path: 'job_title',
            name: 'base.job_title',
            component: () => import('@/views/base/jobTitle'),
          },
          {
            path: 'certificate',
            name: 'base.certificate',
            component: () => import('@/views/base/certificate'),
          },
          {
            path: 'orginfo',
            name: 'base.orginfo',
            component: () => import('@/views/base/orgInfo'),
          }
        ]
      },
      {
        path: '/authority',
        name: 'authority',
        component: () => import('@/views/authority'),
        children: [
          {
            path: 'user',
            name: 'authority.user',
            component: () => import('@/views/authority/user'),
          },
          {
            path: 'role',
            name: 'authority.role',
            component: () => import('@/views/authority/role'),
          },
          {
            path: 'menu',
            name: 'authority.menu',
            component: () => import('@/views/authority/menu'),
          },
          {
            path: 'fun',
            name: 'authority.fun',
            component: () => import('@/views/authority/fun')
          },
          {
            path: 'sys_config',
            name: 'authority.sys_config',
            component: () => import('@/views/authority/sysConfig')
          },
          {
            path: 'status',
            name: 'authority.status',
            component: () => import('@/views/status/index')
          },
          {
            path: 'operation_log',
            name: 'authority.operation_log',
            component: () => import('@/views/authority/operationLog')
          }
        ]
      },
      {
        path: '/appraisal',
        name: 'appraisal',
        component: () => import('@/views/appraisal'),
        children: [
          {
            path: 'manage',
            name: 'appraisal.manage',
            component: () => import('@/views/appraisal/manage'),
          },
          {
            path: 'ready',
            name: 'appraisal.ready',
            component: () => import('@/views/appraisal/ready'),
          },
          {
            path: 'doing',
            name: 'appraisal.doing',
            component: () => import('@/views/appraisal/doing'),
          },
          {
            path: 'checking',
            name: 'appraisal.checking',
            component: () => import('@/views/appraisal/checking'),
          },
          {
            path: 'question',
            name: 'appraisal.question',
            component: () => import('@/views/appraisal/question'),
          },
          {
            path: 'vote',
            name: 'appraisal.vote',
            component: () => import('@/views/appraisal/vote'),
          },
          {
            path: 'my',
            name: 'appraisal.my',
            component: () => import('@/views/appraisal/my'),
          }
        ]
      },
      {
        path: '/config',
        name: 'config',
        component: () => import('@/views/config/index'),
        children: [
          {
            path: 'select',
            name: 'config.select',
            component: () => import('@/views/config/select')
          }
        ]
      },
      {
        path: '/doc',
        name: 'doc',
        component: () => import('@/views/doc/index'),
        children: [
          {
            path: 'manage',
            name: 'doc.manage',
            component: () => import('@/views/doc/manage')
          },
          {
            path: 'handle',
            name: 'doc.handle',
            component: () => import('@/views/doc/handle')
          },
          {
            path: 'detail',
            name: 'doc.detail',
            component: () => import('@/views/doc/detail')
          }
        ]
      },
      {
        path: '/count',
        name: 'count',
        component: () => import('@/views/count/index'),
        children: [
          {
            path: 'base',
            name: 'count.base',
            component: () => import('@/views/count/base')
          },
          {
            path: 'home',
            name: 'count.home',
            component: () => import('@/views/count/home')
          },
          {
            path: 'count',
            name: 'count.count',
            component: () => import('@/views/count/count')
          }
        ]
      },
      {
        path: '/resume',
        name: 'resume',
        component: () => import('@/views/resume/router'),
        children: [
          {
            path: 'index',
            name: 'resume.index',
            component: () => import('@/views/resume/index')
          },
          {
            path: 'details',
            name: 'resume.details',
            component: () => import('@/views/resume/details'),
            children: [
              {
                path: 'base',
                name: 'resume.details.base',
                component: () => import('@/views/resume/details/base'),
              },
              {
                path: 'work',
                name: 'resume.details.work',
                component: () => import('@/views/resume/details/work'),
              },
              {
                path: 'edu',
                name: 'resume.details.edu',
                component: () => import('@/views/resume/details/edu'),
              },
              {
                path: 'job',
                name: 'resume.details.job',
                component: () => import('@/views/resume/details/job'),
              },
              {
                path: 'rew',
                name: 'resume.details.rew',
                component: () => import('@/views/resume/details/rew'),
              },
              {
                path: 'train',
                name: 'resume.details.train',
                component: () => import('@/views/resume/details/train'),
              },
              {
                path: 'certificate',
                name: 'resume.details.certificate',
                component: () => import('@/views/resume/details/certificate/index'),
              },
              {
                path: 'certificatePra',
                name: 'resume.details.certificatePra',
                component: () => import('@/views/resume/details/certificate/pra'),
              },
              {
                path: 'certificateQua',
                name: 'resume.details.certificateQua',
                component: () => import('@/views/resume/details/certificate/qua'),
              },
              {
                path: 'certificateOther',
                name: 'resume.details.certificateOther',
                component: () => import('@/views/resume/details/certificate/other'),
              },
              {
                path: 'all',
                name: 'resume.details.all',
                component: () => import('@/views/resume/details/all'),
              },
              {
                path: 'all',
                name: 'resume.details.all',
                component: () => import('@/views/resume/details/all'),
              }
            ]
          },
          {
            path: 'preview',
            name: 'resume.preview',
            component: () => import('@/views/resume/preview')
          },
          {
            path: 'print',
            name: 'resume.print',
            component: () => import('@/views/resume/print')
          }
        ]
      },
      {
        path: '/report',
        name: 'report',
        component: () => import('@/views/report/router'),
        children: [
          { path: 'nucleic/print', name: 'report.nucleic.print', component: () => import('@/views/report/nucleic/print') },
          { path: 'nucleic/data', name: 'report.nucleic.data', component: () => import('@/views/report/nucleic/data') },
          { path: 'nucleic/result', name: 'report.nucleic.result', component: () => import('@/views/report/nucleic/result') },
          { path: 'nucleic/counts', name: 'report.nucleic.counts', component: () => import('@/views/report/nucleic/counts') }
        ]
      },
      {
        path: '/analysis',
        name: 'analysis',
        component: () => import('@/views/analysis/router'),
        children: [
          { path: 'age', name: 'analysis.age', component: () => import('@/views/analysis/age') },
          { path: 'medical', name: 'analysis.medical', component: () => import('@/views/analysis/medical') }
        ]
      },
      {
        path: '/evaluation',
        name: 'evaluation',
        component: () => import('@/views/evaluation/router'),
        children: [
          {
            path: 'index',
            name: 'evaluation.index',
            component: () => import('@/views/evaluation/index')
          },
          {
            path: 'details',
            name: 'evaluation.details',
            component: () => import('@/views/evaluation/details'),
            children: [
              {
                path: 'draw',
                name: 'evaluation.details.draw',
                props: { type: 'draw' },
                component: () => import('@/views/evaluation/details/draw')
              },
              {
                path: 'opinion',
                name: 'evaluation.details.opinion',
                props: { type: 'opinion' },
                component: () => import('@/views/evaluation/details/draw')
              },
              {
                path: 'approve',
                name: 'evaluation.details.approve',
                props: { type: 'approve' },
                component: () => import('@/views/evaluation/details/approve')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => /iphone|ios|android|ipad/i.test(navigator.userAgent) ? import('@/views/login/mobile') : import('@/views/login/index')
  },
  {
    path: '/screen',
    name: 'screen',
    component: () => import('@/views/appraisal/screen')
  },
  {
    path: '/wap',
    name: 'wap',
    component: () => import('@/views/wap/index'),
    children: [
      {
        path: 'menu',
        name: 'wap.menu',
        component: () => import('@/views/wap/menu'),
      },
      {
        path: 'salary',
        name: 'wap.salary',
        component: () => import('@/views/wap/salary'),
      },
      {
        path: 'salary_tongcheng',
        name: 'wap.salary_tongcheng',
        component: () => import('@/views/wap/salaryTongcheng'),
      },
      {
        path: 'checking',
        name: 'wap.checking',
        component: () => import('@/views/appraisal/checking'),
      },
      {
        path: 'my',
        name: 'wap.my',
        component: () => import('@/views/appraisal/my'),
      },
      {
        path: 'question',
        name: 'wap.question',
        component: () => import('@/views/appraisal/question'),
      },
      {
        path: 'vote',
        name: 'wap.vote',
        component: () => import('@/views/appraisal/vote'),
      },
      {
        path: 'all_info',
        name: 'wap.all_info',
        component: () => import('@/views/wap/allInfo'),
      },
      {
        path: 'worker_work',
        name: 'wap.worker_work',
        component: () => import('@/views/wap/worker/workerDataWork'),
      },
      {
        path: 'worker_base',
        name: 'wap.worker_base',
        component: () => import('@/views/wap/worker/workerDataBase'),
      },
      {
        path: 'edu',
        name: 'wap.edu',
        component: () => import('@/views/wap/worker/eduData'),
      },
      {
        path: 'his_work',
        name: 'wap.his_work',
        component: () => import('@/views/wap/worker/hisWorkData'),
      },
      {
        path: 'job_title',
        name: 'wap.job_title',
        component: () => import('@/views/wap/worker/jobTitleData'),
      },
      {
        path: 'rew',
        name: 'wap.rew',
        component: () => import('@/views/wap/worker/rew'),
      },
      {
        path: 'train',
        name: 'wap.train',
        component: () => import('@/views/wap/worker/train'),
      },
      {
        path: 'certificate',
        name: 'wap.certificate',
        component: () => import('@/views/wap/worker/certificateData'),
      },
      {
        path: 'certificate_doctor_pra',
        name: 'wap.certificate_doctor_pra',
        component: () => import('@/views/wap/worker/certificateDataDoctorPra'),
      },
      {
        path: 'certificate_doctor_qua',
        name: 'wap.certificate_doctor_qua',
        component: () => import('@/views/wap/worker/certificateDataDoctorQua'),
      },
      {
        path: 'certificate_pro_hire',
        name: 'wap.certificate_pro_hire',
        component: () => import('@/views/wap/worker/certificateDataProHire'),
      },
      {
        path: 'certificate_other',
        name: 'wap.certificate_other',
        component: () => import('@/views/wap/worker/certificateDataOther'),
      },
      {
        path: 'idcard',
        name: 'wap.idcard',
        component: () => import('@/views/wap/worker/idCard'),
      },
      {
        path: 'form_page',
        name: 'wap.form_page',
        component: () => import('@/views/wap/worker/formPage'),
      },
      {
        path: 'doc/handle',
        name: 'wap.doc.handle',
        component: () => import('@/views/wap/doc/handle')
      },
      {
        path: 'doc/detail',
        name: 'wap.doc.detail',
        component: () => import('@/views/wap/doc/detail')
      },
      {
        path: 'doc/step',
        name: 'wap.doc.step',
        component: () => import('@/views/wap/doc/step')
      }
    ]
  },
  {
    path: '/print/test',
    name: 'print.test',
    component: () => import('@/components/base/file/test')
  }
]
